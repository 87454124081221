import React, { useEffect, useState } from "react";

export default function useUserAgent() {
  const [isMobile, setIsMobile] = useState(null);
  const [userAgent, setUserAgent] = useState(null);
  const [isIOS, setIsIOS] = useState(null);
  const [isStandalone, setIsStandalone] = useState(null);
  const [userAgentString, setUserAgentString] = useState(null);

  function isPwa(passedWindow) {
    return ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) =>
        passedWindow.matchMedia("(display-mode: " + displayMode + ")").matches,
    );
  }
  useEffect(() => {
    if (window) {
      const userAgentString = window.navigator.userAgent;
      setUserAgentString(userAgentString);
      let userAgent;
      if (userAgentString.indexOf("Chrome") > -1) {
        userAgent = "Chrome";
      } else if (userAgentString.indexOf("Safari") > -1) {
        userAgent = "Safari";
      } else {
        userAgent = "unknown";
      }
      setUserAgent(userAgent);

      // Check if user agent is mobile
      const isIOS = userAgentString.match(/iPhone|iPad|iPod/i);
      const isAndroid = userAgentString.match(/Android/i);
      setIsIOS(isIOS ? true : false);
      const isMobile = isIOS || isAndroid;
      setIsMobile(!!isMobile);
      // Check if app is installed (if it's installed we wont show the prompt)
      if (isPwa(window)) {
        setIsStandalone(true);
      }
    }
  }, []);
  return { isMobile, userAgent, isIOS, isStandalone, userAgentString };
}
