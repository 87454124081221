import AddToHomeScreen from 'components/notifications/AddToHomeScreen'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import I18n from '@sholdi/providers/I18n'

import publicBS from '@sholdi/locales/public-bs.json'

import useSettings from '@sholdi/hooks/useSettings'
import useUserAgent from '@sholdi/hooks/useUserAgent'

import Image from '@sholdi/primitives/atoms/Image'
import Text from '@sholdi/primitives/atoms/Text'
import Container from '@sholdi/ui/molecules/Container'
import MainNavbar from '@sholdi/ui/organisms/MainNavbar'

const Footer = dynamic(() => import('@sholdi/ui/organisms/Footer'), {
  loading: () => <p>Loading...</p>,
})

const MainLayout = ({
  children,
  fluid,
  subheader,
  subFooter,
  childrenProps,
  className,
  megaMenuBanner,
  // ...props
}) => {
  const footerRef = useRef()
  const { isMobile, isIOS } = useUserAgent()
  const settings = useSettings()
  const { menu, footerMenus, logo, shopName, isPaymentConfigSet } = settings

  // TODO load custom mobile menu instead of footer menu for the main mobile menu
  return (
    <div className="w-full bg-white text-[rgb(34,34,34)]">
      <I18n locale="BS" lngDict={publicBS}>
        <MainNavbar
          menu={menu}
          megaMenuBanner={megaMenuBanner}
          mobileMenuItems={menu?.menuItems || []}
          logo={() => (
            <Image src={logo} alt={shopName} width={100} height={100} ar="custom" />
          )}
        />
        {subheader && subheader}
        <Container fluid={fluid} className={className}>
          <div className="flex min-h-screen w-full pb-8">
            <div
              className={twMerge(
                'w-full flex flex-1 justify-center pt-6',
                childrenProps?.className,
              )}
              {...childrenProps}
            >
              {children}
            </div>
          </div>
        </Container>
        {subFooter && subFooter}
        <Footer
          label={() => <Text className="text-white">Powered by Sholdi</Text>}
          logo={() => (
            <Image
              src={logo}
              alt={shopName}
              width={80}
              height={80}
              className="transform scale-75"
              ar="custom"
            />
          )}
          menus={footerMenus}
          showCards={!!isPaymentConfigSet}
          ref={footerRef}
        />
        {isMobile && isIOS && <AddToHomeScreen />}
      </I18n>
    </div>
  )
}

MainLayout.displayName = 'MainLayout'

MainLayout.propTypes = {
  children: PropTypes.node,
  fluid: PropTypes.bool,
  myAccountMenuContent: PropTypes.shape({}),
  subheader: PropTypes.node,
  subFooter: PropTypes.node,
  megaMenuBanner: PropTypes.shape({}),
  childrenProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default MainLayout
