import { memo, forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import NextLink from "next/link";
import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

// Define Tailwind-based variants for Link
const linkClass = cva(
  [
    "relative p-0 font-body text-black transition-colors duration-250 ease-in-out hover:underline cursor-pointer",
  ],
  {
    variants: {
      variant: {
        blank: "text-inherit font-inherit font-heading hover:no-underline",
        primaryButton: "flex justify-center items-center px-4 py-2.5",
        profile:
          "text-primary hover:no-underline leading-6 font-heading font-normal",
        profileSubLink: "text-sm text-primary leading-5 font-heading",
        nav: "text-primary-text opacity-70 hover:opacity-100 hover:no-underline",
        helpLinks:
          "text-gray-50 mr-11 opacity-70 hover:opacity-100 hover:no-underline",
      },
    },
    defaultVariants: {
      variant: "none",
    },
  },
);

const Link = forwardRef(
  ({ href, variant, prefetch, passHref = true, className, ...props }, ref) => {
    const combinedClasses = useMemo(
      () => twMerge(linkClass({ variant }), className),
      [className, variant],
    );

    return (
      <NextLink
        href={href}
        prefetch={prefetch}
        passHref={passHref}
        ref={ref}
        className={combinedClasses}
        {...props}
      />
    );
  },
);

Link.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  variant: PropTypes.string,
  prefetch: PropTypes.bool,
  passHref: PropTypes.bool,
  className: PropTypes.string,
};

Link.displayName = "Link";

export default memo(Link);
