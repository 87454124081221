import PropTypes from "prop-types";

import useSettings from "@sholdi/hooks/useSettings";
import { useRouter } from "next/router";

import Text from "@sholdi/primitives/atoms/Text";
import IconButton from "@sholdi/primitives/atoms/IconButton";
import ArrowRight from "@sholdi/icons/ArrowRight";
import Image from "@sholdi/primitives/atoms/Image";

const MegaMenuBanner = ({ position, megaMenuBanner }) => {
  const router = useRouter();
  const settings = useSettings();
  const { shopName } = settings;
  return (
    <div className="w-[32%] relative">
      <div
        style={{
          background:
            "linear-gradient(180deg, #FFFFFF 0.75%, rgba(255, 255, 255, 0) 48.48%), linear-gradient(360deg, rgba(0, 0, 0, 0.64) 13.2%, rgba(0, 0, 0, 0) 45.39%) ",
        }}
        className="relative h-full p-6 flex flex-col justify-between z-10"
      >
        <div className="text-4xl font-heading font-semibold pb-4">
          {megaMenuBanner.sectionTitle}
        </div>
        <Text>{megaMenuBanner.sectionSubtitle}</Text>

        <div className="flex justify-between items-center mt-auto">
          <div>
            <div className="text-white font-semibold font-heading text-sm mb-2">
              {shopName}
            </div>

            <div className="text-white font-semibold font-heading text-2xl mb-2">
              {megaMenuBanner.cta.label}
            </div>
          </div>
          <IconButton
            onClick={() => router.push(megaMenuBanner.cta.href)}
            className="size-16 text-white rounded-full bg-primary-main"
          >
            <ArrowRight className="size-16" />
          </IconButton>
        </div>
      </div>

      <Image
        layout="fill"
        objectFit="cover"
        alt={`roštilj-banner-${position}`}
        src={megaMenuBanner.sectionImage}
        className="absolute top-0 left-0 w-full w-full object-cover"
      ></Image>
    </div>
  );
};

MegaMenuBanner.displayName = "MegaMenuBanner";

MegaMenuBanner.propTypes = {
  position: PropTypes.string,
  megaMenuBanner: PropTypes.shape({
    sectionTitle: PropTypes.string,
    sectionImage: PropTypes.string,
    sectionSubtitle: PropTypes.string,
    cta: PropTypes.shape({
      href: PropTypes.string,
      label: PropTypes.string,
    }),
  }),
};

export default MegaMenuBanner;
