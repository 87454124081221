import { FRAGMENT_BASIC_PRODUCT_DETAILS } from '../../fragments/products'

const PROMOTIONS_BY_POSITION_QUERY = `
query PromotionsQuery($position: PromotionPositionTypesEnum, $limit: Int, $offset: Int) {
  promotions(where: { positionKey: { _eq: $position } }, limit: $limit, offset: $offset) {
    __typename
    id
    expiresAt
    product {
      ...BasicProductDetails
    }
  }
} 
${FRAGMENT_BASIC_PRODUCT_DETAILS}
`

export default PROMOTIONS_BY_POSITION_QUERY
