import PropTypes from "prop-types";

import Box from "@sholdi/primitives/atoms/Box";
import Flex from "@sholdi/primitives/atoms/Flex";
import Link from "@sholdi/primitives/atoms/Link";

function Item({ href = "/", title = "", icon: Icon = null }) {
  return (
    <Flex
      as={Link}
      href={href}
      className="w-full flex items-center gap-3 px-3 py-4 border-b border-gray-200 text-gray-900 font-body no-underline cursor-pointer hover:no-underline"
    >
      {Icon && <Icon className="size-6" />}
      <Box>{title}</Box>
    </Flex>
  );
}

Item.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
};

export default Item;
