import { useState } from 'react'

const useDelayedHover = (hoverAction, delay = 300) => {
  const [delayHandler, setDelayHandler] = useState(null)

  const handleMouseEnter = event => {
    setDelayHandler(
      setTimeout(() => {
        if (hoverAction) {
          hoverAction(event)
        }
      }, delay)
    )
  }

  const handleMouseLeave = () => {
    clearTimeout(delayHandler)
  }

  return { handleMouseEnter, handleMouseLeave }
}

export default useDelayedHover
