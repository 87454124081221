import { useMemo, useState, forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import useTranslation from "@sholdi/hooks/useTranslation";

import { getComponentProps } from "@sholdi/shared/helpers/getComponentProps";

import { INPUT_PROPS } from "../Field/utils";

import SearchIcon from "@sholdi/icons/Search";
import IconButton from "@sholdi/primitives/atoms/IconButton";

import { SearchInput, SearchWrapper } from "./components";

const Search = forwardRef(
  (
    {
      value,
      placeholder = "search",
      variant,
      children,
      before,
      after,
      ariaLabel = "search",
      onChange,
      className,
      ...props
    },
    ref,
  ) => {
    const [expanded, setExpanded] = useState(false);

    const { t } = useTranslation();

    const isSlideVariant = variant === "slide";

    const { componentProps } = useMemo(
      () => getComponentProps(props, INPUT_PROPS),
      [props],
    );

    return (
      <SearchWrapper
        className={twMerge(
          "relative justify-between items-center px-2 md:px-3 py-2 border border-gray-100 rounded h-10 bg-white",
          className,
        )}
        variant={variant}
        expanded={expanded}
      >
        {before && before}
        <SearchInput
          aria-label={ariaLabel}
          name="search"
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder={t(placeholder)}
          className="h-full leading-6 text-base flex-1 border-none placeholder:text-gray-500 outline-none"
          variant={variant}
          value={value}
          onChange={onChange}
          ref={ref}
          expanded={expanded}
          {...componentProps}
        />
        {after && after}
        {children}
        {isSlideVariant && (
          <IconButton
            onClick={() => setExpanded((previousValue) => !previousValue)}
            className="h-full size-4 flex-none p-0 -ml-7 rounded-full  cursor-pointer stroke-black fill-transparent"
          >
            <SearchIcon />
          </IconButton>
        )}
      </SearchWrapper>
    );
  },
);

Search.displayName = "Search";

Search.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(["none", "slide", "adminNavbar", "messengerSearch"]),
  value: PropTypes.string,
  children: PropTypes.node,
  showButton: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  before: PropTypes.node,
  after: PropTypes.node,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default memo(Search);
