import PropTypes from "prop-types";
import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const Input = forwardRef((props, ref) => (
  <input
    ref={ref}
    className={twMerge(
      "font-base border border-gray-300 text-black bg-transparent font-normal leading-normal block w-full font-body appearance-none outline-none",
      props?.className || "",
    )}
    as="input"
    {...props}
  />
));

Input.displayName = "Input";

Input.propTypes = {
  className: PropTypes.string,
};

export default Input;
