import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const ArrowRight = ({ strokeWidth = "1", className, ...props }) => (
  <SVG
    aria-label="ArrowRight"
    className={twMerge("w-3 h-3", className)}
    {...props}
  >
    <path
      d="M5 12H19M19 12L12 5M19 12L12 19"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

ArrowRight.propTypes = {
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default ArrowRight;
