import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const Burger = ({ className, ...props }) => (
  <SVG
    viewBox="0 0 20 14"
    fill="none"
    aria-label="Burger"
    className={twMerge("w-5 h-3.5 stroke-current fill-none", className)}
    {...props}
  >
    <path
      d="M1 7H19M1 1H19M1 13H19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

Burger.displayName = "Burger";

Burger.propTypes = {
  className: PropTypes.string,
};

export default Burger;
