import { useQuery } from "urql";

const useQueryHook = (
  query,
  { lazy = false, skip = false, ...hookOptions } = {},
) => {
  const [{ data, fetching: loading, error, ...rest }, executeQuery] = useQuery({
    query,
    pause: skip || lazy,
    ...hookOptions,
  });

  return {
    data,
    loading,
    error,
    refetch: executeQuery,
    ...rest,
  };
};

export default useQueryHook;
