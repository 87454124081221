import { memo } from "react"
import PropTypes from "prop-types";
import Flex from "@sholdi/primitives/atoms/Flex";
import ArrowRight from "@sholdi/icons/ArrowRight";
import PreviousCategory from "./PreviousCategory";
import Link from "@sholdi/primitives/atoms/Link";

const MenuList = ({ category, onChoseParent, menuItems, onPrevious }) => (
  <div className="h-[calc(100vh-93px)] w-full overflow-y-auto m-0 px-3">
    {category && (
      <PreviousCategory onPrevious={onPrevious} category={category} />
    )}
    {menuItems.map((item, i) => (
      <Flex
        as={Link}
        key={`${item.label}-${i}-${category ? category.id : ""}`}
        className="w-full flex items-center justify-between px-3 py-4 font-body text-gray-900 no-underline cursor-pointer hover:no-underline"
        {...(item?.menuItems?.length > 0
          ? {
              onClick: () => onChoseParent(item),
              href: "#",
            }
          : {
              href: item.link,
            })}
      >
        <div>{item.title}</div>
        {item?.menuItems?.length > 0 && <ArrowRight className="size-6" />}
      </Flex>
    ))}
  </div>
);

MenuList.displayName = "MenuList";
MenuList.propTypes = {
  category: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  menuItems: PropTypes.array.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onChoseParent: PropTypes.func.isRequired,
};

export default memo(MenuList);
