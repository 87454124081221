import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const ArrowLeft = ({ strokeWidth = "1", className, ...props }) => (
  <SVG
    aria-label="ArrowLeft"
    className={twMerge("w-3 h-3", className)}
    {...props}
  >
    <path
      d="M19 12H5M5 12L12 19M5 12L12 5"
      stroke="currentColor"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

ArrowLeft.propTypes = {
  className: PropTypes.string,
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ArrowLeft;
