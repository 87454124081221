import { useMemo, useState, useCallback } from "react";
import { useRouter } from "next/router";
import useDebouncedCallback from "./useDebounceCallback";
import ElasticApiConnector from "@sholdi/services/ElasticApiConnector";
import useGetPromotions from "@sholdi/graphql/operations/useGetPromotions";

const connector = new ElasticApiConnector();

// Move SEARCH_OPTIONS outside the component to prevent unnecessary re-creation
export const SEARCH_OPTIONS = {
  search_fields: {
    name: {
      weight: 10,
    },
    category_1: {
      weight: 5,
    },
    category_2: {
      weight: 5,
    },
    category_3: {
      weight: 5,
    },
    category_4: {
      weight: 5,
    },
    // category_5: {
    //   weight: 5,
    // },
    shop: {
      weight: 5,
    },
    brand: {
      weight: 10,
    },
  },
  result_fields: {
    id: { raw: {} },
    name: { raw: {} },
    category_1: { raw: {} },
    category_2: { raw: {} },
    category_3: { raw: {} },
    category_4: { raw: {} },
    product_id: {
      raw: {},
    },
    // category_5: { raw: {} },
    shop: { raw: {} },
    slug: { raw: {} },
    price: { raw: {} },
    featured_image: { raw: {} },
    sale_price: { raw: {} },
    shop_id: { raw: {} },
    shop_slug: { raw: {} },
    shop_logo: { raw: {} },
    sholdi_product_id: { raw: {} },
  },
  page: { size: 400, current: 1 },
};

const useSearch = () => {
  const router = useRouter();
  const [searchState, setSearchState] = useState({
    results: [],
    suggestions: [],
    term: router.query?.q || "",
    isLoading: false,
  });

  const { data, loading: featuredProductsLoading } = useGetPromotions({
    variables: { position: "HOME_SEARCH", limit: 6, offset: 0 },
  });
  const { promotions = [] } = data || {};

  const config = useMemo(
    () => ({
      apiConnector: connector,
      resultsPerPage: 5,
      result_fields: { ...SEARCH_OPTIONS.result_fields },
      search_fields: { ...SEARCH_OPTIONS.search_fields },
    }),
    [],
  );

  const performSearch = useCallback(
    (value) => {
      if (value.length) {
        setSearchState((prev) => ({ ...prev, isLoading: true, results: [] }));
        connector
          .onAutocomplete({ searchTerm: value }, config)
          .then((results) => {
            const {
              autocompletedResults,
              autocompletedSuggestions: { documents },
            } = results || {};
            setSearchState((prev) => ({
              ...prev,
              isLoading: false,
              results: autocompletedResults || [],
              suggestions: documents || [],
            }));
          })
          .catch(console.log);
      } else {
        setSearchState((prev) => ({ ...prev, results: [] }));
      }
    },
    [config],
  );

  const debouncedSearch = useDebouncedCallback(performSearch, 400);

  const onChange = useCallback(
    (e) => {
      const value = e.target.value;
      setSearchState((prev) => ({ ...prev, term: value }));
      debouncedSearch(value);
    },
    [debouncedSearch],
  );

  const suggestedProducts = useMemo(
    () => promotions.map((promotion) => promotion.product),
    [promotions],
  );

  return {
    onChange,
    searchResults: searchState.results,
    searchTerm: searchState.term,
    isLoading: searchState.isLoading,
    setSearchTerm: useCallback(
      (term) => setSearchState((prev) => ({ ...prev, term })),
      [],
    ),
    searchSuggestions: searchState.suggestions,
    suggestedProducts,
    featuredProductsLoading,
  };
};

export default useSearch;
