export const shapes = {
  circle: "rounded-full overflow-hidden",
  rectangle: "rounded border-white",
};

export const sizes = {
  avatarXs: "w-6 h-6",
  avatarSm: "w-8 h-8",
  avatarMd: "w-10 h-10",
  avatarLg: "w-12 h-12",
  avatarXl: "w-14 h-14",
  avatarXxl: "w-16 h-16",
};
