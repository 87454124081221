import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import SVG from "./SVG";

const Close = ({ className, strokeWidth = 2, ...props }) => (
  <SVG aria-label="Close" className={twMerge("w-4 h-4", className)} {...props}>
    <path
      d="M18 6L6 18M6 6L18 18"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

Close.propTypes = {
  className: PropTypes.string,
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Close;
