import { memo, forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

import Box from "./Box";

const Flex = forwardRef(({ className, ...props }, ref) => (
  <Box className={twMerge("flex", className)} ref={ref} {...props} />
));

Flex.displayName = "Flex";

Flex.propTypes = { className: PropTypes.string };

export default memo(Flex);
