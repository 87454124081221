export const FRAGMENT_PRODUCT_DESCRIPTIONS = `
  fragment ProductDescription on ProductDescriptions {
    __typename
    id
    createdAt
    description
    localeKey
    metaDescription
    metaKeywords
    name
    productId
    shortDescription
    slug
    updatedAt
  }
`;

export const FRAGMENT_PRODUCT_DESCRIPTIONS_MINIMAL = `
  fragment MinimalProductDescription on ProductDescriptions {
    __typename
    id
    name
    description
    localeKey
    shortDescription
    slug
  }
`;

export const FRAGMENT_PRODUCT_IMAGES = `
  fragment FullProductImage on ProductImages {
    __typename
    id
    productId
    isFeatured
    path
    sort
  }
`;

export const FRAGMENT_VARIATION_DESCRIPTION = `
  fragment VariationDescription on VariationDescriptions {
    __typename
    id
    localeKey
    name
    description
  }
`;

export const FRAGMENT_VARIATION_OPTION = `
  fragment VariationOption on VariationOptions {
    __typename
    id
    variationOptionDescriptions {
      description
      localeKey
      name
    }
  }
`;
export const FRAGMENT_PRODUCT_OPTION_IMAGES = `
  fragment ProductOptionImage on ProductOptionImages {
    __typename
   productVariationOption {
    __typename
    id
    variationOption {
      id
      variationOptionDescriptions {
        name
        localeKey
      }
    }
   }
  }
`;

export const FRAGMENT_PRODUCT_VARIATION_OPTION = `
  fragment ProductVariationOption on ProductVariationOptions {
    __typename
    isDefault
    id
    variationOption {
      ...VariationOption
    }
    variationOptionId
  }
  ${FRAGMENT_VARIATION_OPTION}
`;
export const FRAGMENT_PRODUCT_VARIATION = `
  fragment ProductVariation on ProductVariations {
    id
    variationId
    productId
    variation {
      id
      variationKey
      hasImages
      isSearchable
      filterKey
      allowCombinations
      variationDescriptions {
        ...VariationDescription
      }
      variationOptions {
        ...VariationOption
      }
    }
    variationOptionId
    variationOption {
      ...VariationOption
    }
    value
  }
  ${FRAGMENT_VARIATION_DESCRIPTION}
  ${FRAGMENT_VARIATION_OPTION}
`;

export const FRAGMENT_PRODUCT_STOCK_TRANSACTION = `
  fragment StockTransaction on ProductStockTransactions {
    __typename
    id
    createdAt
    stockAmount
    status
    warehouseId
    totalUnitPrice
    unitPrice
  }
`;

export const FRAGMENT_PRODUCT_COMBINATIONS = `
  fragment ProductCombination on ProductCombinations {
    __typename
    id
    combinationString
    availableStock
    availableFrom
    createdAt
    isDefault
    price
    salePrice
    sku
    ucis
    barcode
    ean
  }
`;

export const FRAGMENT_PRODUCT_COMBINATIONS_MINIMAL = `
fragment MinimalProductCombination on ProductCombinations {
  __typename
  id
  price
  salePrice
  combinationString
  availableStock
  ucis
  allowBackorder
  barcode
  settings
}
`;

export const FRAGMENT_BASIC_PRODUCT_DETAILS = `
  fragment BasicProductDetails on Products {
    id
    categoryId
    statusKey
    conditionKey
    catalogItemId
    shopId
    shop {
      id
      ownerId
      name
    }
    manufacturer {
      id
      name
    }
    category {
      id
      categoryDescriptions {
        id
        name
        localeKey
      }
    }
    productImages {
      ...FullProductImage
    }
    productTags {
      __typename
      productId
      tag {
        __typename
        id
        name
      }
    }
    productImage {
      ...FullProductImage
    }
    productDescriptions {
      ...ProductDescription
    }
    productCombinations {
      ...ProductCombination
    }
    productCombination {
      ...ProductCombination
    }
  }
  ${FRAGMENT_PRODUCT_IMAGES}
  ${FRAGMENT_PRODUCT_DESCRIPTIONS}
  ${FRAGMENT_PRODUCT_COMBINATIONS}
  `;

export const FRAGMENT_PRODUCT_IMAGE_MINIMAL = `
  fragment MinProductImage on ProductImages {
    __typename
    id
    path
    isFeatured
  }
`;

export const PRODUCT_CARD_DETAILS_FRAGMENT = /* GraphQL */ `
  fragment ProductCardDetails on Products {
    __typename
    id
    shopId
    shop {
      name
      slug
    }
    catalogItemId
    productImages {
      __typename
      id
      path
      isFeatured
    }
    productDescriptions {
      __typename
      id
      name
      slug
      localeKey
    }
    # might be redundant
    productCombination {
      __typename
      id
      availableStock
      price
      salePrice
      ucis
      isExternal
      settings
    }
    productTags {
      tag {
        name
      }
    }
    category {
      categoryDescriptions {
        name
      }
    }
    productCombinationsAggregate {
      aggregate {
        count
      }
    }
  }
`;

export const FRAGMENT_MINIMAL_PRODUCT_DETAILS = `
fragment MinimalProductDetails on Products {
  id
  categoryId
  statusKey
  conditionKey
  catalogItemId
  shopId
  shop {
    id
    ownerId
    name
  }
  manufacturer {
    id
    name
  }
  category {
    id
    categoryDescriptions {
      id
      name
      localeKey
    }
  }
  productImages {
    ...MinProductImage
  }
  productImage {
    ...MinProductImage
  }
  productDescriptions {
    ...MinimalProductDescription
  }
  productCombinations {
    ...MinimalProductCombination
  }
  productCombination {
    ...MinimalProductCombination
  }
  productTags {
    __typename
    tag {
      id
      name
      isFeatured
    }
  }
}
${FRAGMENT_PRODUCT_COMBINATIONS_MINIMAL}
${FRAGMENT_PRODUCT_IMAGE_MINIMAL}
${FRAGMENT_PRODUCT_DESCRIPTIONS_MINIMAL}
`;
