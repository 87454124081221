export const getComponentProps = (props = {}, whiteListProps = []) =>
  Object.keys(props).reduce(
    (acc, next) => {
      if (typeof props[next] !== 'undefined') {
        if (whiteListProps.includes(next)) {
          acc['componentProps'][next] = props[next]
        } else {
          acc[next] = props[next]
        }
      }

      return acc
    },
    { componentProps: {} }
  )
