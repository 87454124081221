export const INPUT_PROPS = [
  "value",
  "onChange",
  "name",
  "touched",
  "onBlur",
  "onFocus",
  "min",
  "max",
  "type",
  "placeholder",
  "disabled",
  "initialTouched",
  "initialValue",
  "defaultValue",
  "checked",
  "rows",
  "aria-label",
  "autoComplete",
  "id",
  "inputMode",
  "maxLength",
  "data-testid",
  "onKeyDown",
];
