import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import { useState, useEffect, useMemo, memo } from 'react'

import useUserAgent from '@sholdi/hooks/useUserAgent'

import Box from '@sholdi/primitives/atoms/Box'


const AddToOtherBrowser = dynamic(() => import('./AddToOtherBrowser'))
const AddToIosSafari = dynamic(() => import('./AddToIosSafari'))

const STORAGE_NAME = 'addToHomeScreenPrompt'

const isDifferenceGreaterThan48Hours = givenDate => {
  const currentDate = new Date()
  const diffInMilliseconds = currentDate - new Date(givenDate)
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60)
  return diffInHours > 48
}

const Prompt = memo(({ displayPrompt }) => {
  if (displayPrompt === 'Safari' || displayPrompt === 'Chrome') {
    return <AddToIosSafari />
  }
  if (displayPrompt === 'unknown') {
    return <AddToOtherBrowser />
  }
  return null
})
Prompt.displayName = 'Prompt'
Prompt.propTypes = {
  displayPrompt: PropTypes.string,
}

const AddToHomeScreen = () => {
  const [displayPrompt, setDisplayPrompt] = useState(null)
  const { userAgent, isMobile, isStandalone } = useUserAgent()

  const closePrompt = () => {
    setDisplayPrompt('')
    localStorage.setItem(STORAGE_NAME, new Date().toUTCString())
  }

  useEffect(() => {
    const addToHomeScreenPromptCookie = localStorage.getItem(STORAGE_NAME)

    if (
      isDifferenceGreaterThan48Hours(addToHomeScreenPromptCookie) &&
      isMobile &&
      !isStandalone
    ) {
      setDisplayPrompt(userAgent)
    }
  }, [userAgent, isMobile, isStandalone])

  const memoizedPrompt = useMemo(
    () => <Prompt displayPrompt={displayPrompt} />,
    [displayPrompt],
  )

  if (!displayPrompt) return null

  return (
    <Box
      position="fixed"
      bg="primary"
      bottom={0}
      width="full"
      onClick={closePrompt}
      zIndex={15}
    >
      {memoizedPrompt}
    </Box>
  )
}

export default memo(AddToHomeScreen)
