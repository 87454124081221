import { default as Router } from "next/router";

const isExcludedUrl = (url, patterns) =>
  patterns.some((pattern) => pattern.test(url));

export function push(args) {
  if (typeof window !== "undefined" && window._paq) {
    window._paq.push(args);
  }
}

const startsWith = (str, needle) => str.startsWith(needle);

export function init({
  url,
  siteId,
  excludeUrlsPatterns = [/\catch.+/],
  disableCookies = false,
  onRouteChangeStart,
  onRouteChangeComplete,
  onInitialization,
}) {
  if (typeof window === "undefined") return;

  window._paq = window._paq || [];

  // eslint-disable-next-line no-console
  console.warn("init");

  if (!url) {
    // eslint-disable-next-line no-console
    console.warn("Tracker disabled, please provide tracker url");
    return;
  }

  let previousPath = "";
  const excludedUrl = isExcludedUrl(
    window.location.pathname,
    excludeUrlsPatterns,
  );

  if (onInitialization) onInitialization();

  if (!excludedUrl) {
    push(["trackPageView"]);
  } else {
    // eslint-disable-next-line no-console
    console.log(`matomo: exclude track ${window.location.pathname}`);
  }

  if (disableCookies) {
    push(["disableCookies"]);
  }

  push(["enableLinkTracking"]);
  push(["setTrackerUrl", `${url}/js/sholdi.php`]);
  push(["setSiteId", siteId]);

  previousPath = location.pathname;

  const defaultOnRouteChangeStart = (path) => {
    if (isExcludedUrl(path, excludeUrlsPatterns)) return;

    const [pathname] = path.split("?");

    if (previousPath) {
      push(["setReferrerUrl", previousPath]);
    }
    push(["setCustomUrl", pathname]);
    push(["deleteCustomVariables", "page"]);
    previousPath = pathname;

    if (onRouteChangeStart) onRouteChangeStart(path);
  };

  const defaultOnRouteChangeComplete = (path) => {
    if (isExcludedUrl(path, excludeUrlsPatterns)) return;

    requestIdleCallback(() => {
      const { q } = Router.query;
      push(["setDocumentTitle", document.title]);
      if (startsWith(path, "/recherche") || startsWith(path, "/search")) {
        push(["trackSiteSearch", q ?? ""]);
      } else {
        push(["trackPageView"]);
      }
    });

    if (onRouteChangeComplete) onRouteChangeComplete(path);
  };

  Router.events.on("routeChangeStart", defaultOnRouteChangeStart);
  Router.events.on("routeChangeComplete", defaultOnRouteChangeComplete);
}

export default init;
