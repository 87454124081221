import {
  useRef,
  useEffect,
  forwardRef,
  Children,
  Fragment,
  cloneElement,
  useCallback,
} from "react";
import { mergeRefs } from "react-merge-refs";
import hasParent from "./hasParent";

const ClickOutside = forwardRef(
  ({ active = true, onClick, children, ignore }, forwardedRef) => {
    const innerRef = useRef();
    const child = children ? Children.only(children) : undefined;

    if (!child || child.type === Fragment) {
      throw new Error("A valid non Fragment React Children should be provided");
    }

    if (typeof onClick !== "function") {
      throw new Error("onClick must be a valid function");
    }

    // Use useCallback to prevent recreation of the handleClick function
    const handleClick = useCallback(
      (event) => {
        if (!hasParent(event.target, innerRef.current)) {
          if (!ignore) {
            onClick(event);
          }
        }
      },
      [ignore, onClick] // Dependencies array
    );

    useEffect(() => {
      if (active) {
        document.addEventListener("mousedown", handleClick, { passive: true });
        document.addEventListener("touchstart", handleClick, { passive: true });
      }

      return () => {
        if (active) {
          document.removeEventListener("mousedown", handleClick);
          document.removeEventListener("touchstart", handleClick);
        }
      };
    }, [active, handleClick]); // Re-register listeners only if active or handleClick changes

    const composedRefCallback = (element) => {
      if (typeof child.ref === "function") {
        child.ref(element);
      } else if (child.ref) {
        child.ref.current = element;
      }
    };

    return cloneElement(child, {
      ref: mergeRefs([composedRefCallback, innerRef, forwardedRef]),
    });
  }
);

ClickOutside.displayName = "ClickOutside";

export default ClickOutside;
